import OSRS from "./images/OSRS-comp-screenshot.png";
import CareRing from "./images/CareRing-scrn.png";
import Coconuts from "./images/CoconutsInc-scrn.png";
import CTPGames from "./images/ctpGamesBookScreenshot.png";

const ctpGames = {
  title: "CTPGames Video Game Recommendation Engine",
  about:
    "CTPGames Video Game Recommendation Engine utilizes item-item collaborative filtering to suggest users video game recommendations. Based on a dataset from Metacritic's games of all time collected from Kaggle.",
  techStack: "Python, Pandas, Scikit-learn, Matplotlib, Jupyter Notebook.",
  link: "https://datalore.jetbrains.com/view/report/80BJiNDqotrlhoyb63yaBY",
  linkType: "live",
  image: CTPGames,
  imageAlt: "CTPGames snapshot",
};
const osrsCompetitions = {
  title: "OSRS-Competitions",
  about:
    "OSRS-Competitions is a website that tracks my in-game group's player data from the online game OldSchool RuneScape, providing real-time competition rankings.",
  techStack: "JavaScript, React, Node, and Express.",
  link: "https://osrs-competitions.netlify.app/",
  linkType: "live",
  image: OSRS,
  imageAlt: "OSRS Snapshot",
};
const careRing = {
  title: "CareRing",
  about:
    "CareRing is a mobile app that connects small nonprofit organizations and volunteers locally.",
  techStack: "JavaScript, React Native / Expo, Redux, PostgreSQL, and Neo4j.",
  link: "https://www.github.com/volunteers-connect/care-ring",
  linkType: "github",
  image: CareRing,
  imageAlt: "CareRing Snapshot",
  imageId: "CareRingPic",
};
const coconutsInc = {
  title: "Coconuts, Inc",
  about:
    "Coconuts, Inc is an E-commerce website selling fun tropical-themed products.",
  techStack:
    "JavaScript, React, Redux, Express, PostgreSQL, Sequelize, Passport, and Stripe.",
  link: "https://github.com/team-coconut-tiki/grace-shopper",
  linkType: "github",
  image: Coconuts,
  imageAlt: "CoconutsInc Snapshot",
};
const projectContent = [ctpGames, osrsCompetitions, careRing, coconutsInc]; // order of content

export default projectContent;
