import Chris from "./images/linkedinPhoto.jpeg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin, faGithubSquare } from "@fortawesome/free-brands-svg-icons";
import { useEffect, useState } from "react";
import projectContent from "./ProjectContent";
import workContent from "./WorkContent";

import "./App.css";

function App() {
  const [isMobile, setIsMobile] = useState(false);

  const handleResize = () => {
    if (window.innerWidth < 840) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
  });

  const [linkedInColor, setLinkedInColor] = useState("#1d3557");
  const [gitHubColor, setGitHubColor] = useState("#1d3557");

  return (
    <div className="App">
      <header className="AppHeaderContainer">
        <div className="AppHeader">
          <a href="#top">
            <img
              src={Chris}
              alt="Christopher Pietka profile"
              id="BannerPhoto"
            />
          </a>
          <div className="IconContainer">
            <a
              href="https://www.linkedin.com/in/christopher-pietka/"
              onMouseOver={() => setLinkedInColor("#f1faee")}
              onMouseLeave={() => setLinkedInColor("#1d3557")}
            >
              <FontAwesomeIcon
                icon={faLinkedin}
                size="3x"
                className="HeaderIcons"
                color={linkedInColor}
              />
            </a>
            <a
              href="https://github.com/CrispyQ7"
              id="GHIcon"
              onMouseOver={() => setGitHubColor("#f1faee")}
              onMouseLeave={() => setGitHubColor("#1d3557")}
            >
              <FontAwesomeIcon
                icon={faGithubSquare}
                size="3x"
                className="HeaderIcons"
                color={gitHubColor}
              />
            </a>
          </div>
        </div>
      </header>
      <div className="OuterContainer">
        <img src={Chris} alt="Christopher Pietka profile" id="ProfilePhoto" />
        <h1>Christopher Pietka</h1>
        <p>
          I'm a software engineer living in the Chicago suburbs, working at
          FactSet.
        </p>
        <p>
          I specialize in frontend software development, primarily working in
          JavaScript/TypeScript, and occasionally Python.
        </p>
        <p>
          I utilize tech such as React, Redux, Vue, Angular, Node.js,
          Express.js, Neo4j, and PostgreSQL.
        </p>
        <h2>Work Experience</h2>
        <div className="ProjectsContainer">
          {workContent.map((project, idx) => (
            <div
              className={
                "Project" +
                (isMobile ? "Mobile" : "") +
                " " +
                (idx % 2 === 0 ? "Bubbled" : "Reversed") + // Alternating Bubbles and Column Reverse
                (isMobile ? "Mobile" : "")
              }
              key={idx}
            >
              <div
                className={isMobile ? "ProjectContentMobile" : "ProjectContent"}
              >
                <h3>{project.title}</h3>
                <span>
                  <u>About</u>
                </span>
                <p>{project.about}</p>
                <span>
                  <u>Position</u>
                </span>
                <p>{project.position}</p>
                <span>
                  <u>Tech Used</u>
                </span>
                <p>{project.techStack}</p>
                {project.linkType === "wip" ? (
                  <span>*Live link coming soon!*</span>
                ) : (
                  <a href={project.link}>
                    {project.linkType === "live"
                      ? "Check out the site here!"
                      : "Check it out on GitHub here!"}
                  </a>
                )}
              </div>
              <img
                src={project.image}
                alt={project.imageAlt}
                className={isMobile ? "ProjectContentMobile" : "ProjectContent"}
                id={project.imageId}
              />
            </div>
          ))}
        </div>
        <h2>About Me</h2>
        <p>
          I hold a Bachelor's in Computer Science from Western Governors
          University, Spring 2022.
        </p>
        <p>
          Problem solving is my passion, and I like to express it through
          programming and rock climbing!
        </p>
        <p>
          My experiences have provided me the technical skills necessary to
          flourish in software development and make a positive impact on
          business outcomes.
        </p>
        <h2>Personal Projects</h2>
        <div className="ProjectsContainer">
          {projectContent.map((project, idx) => (
            <div
              className={
                "Project" +
                (isMobile ? "Mobile" : "") +
                " " +
                (idx % 2 === 0 ? "Bubbled" : "Reversed") + // Alternating Bubbles and Column Reverse
                (isMobile ? "Mobile" : "")
              }
              key={idx}
            >
              <div
                className={isMobile ? "ProjectContentMobile" : "ProjectContent"}
              >
                <h3>{project.title}</h3>
                <span>
                  <u>About</u>
                </span>
                <p>{project.about}</p>
                <span>
                  <u>Tech Used</u>
                </span>
                <p>{project.techStack}</p>
                <a href={project.link}>
                  {project.linkType === "live"
                    ? "Check out the site here!"
                    : "Check it out on GitHub here!"}
                </a>
              </div>
              <img
                src={project.image}
                alt={project.imageAlt}
                className={isMobile ? "ProjectContentMobile" : "ProjectContent"}
                id={project.imageId}
              />
            </div>
          ))}
        </div>
      </div>
      <footer>© Christopher Pietka 2023. All rights reserved.</footer>
    </div>
  );
}

export default App;
