import TheNOIS from "./images/theNOIS-scrn.png";
import ACDC from "./images/adamSiteScreenshot.png";
import FactSet from "./images/finance.jpg";

const factSet = {
  title: "FactSet",
  about:
    "FactSet creates flexible, open data and software solutions for tens of thousands of investment professionals around the world, providing instant access to financial data and analytics that investors use to make crucial decisions. ",
  position: "Software Engineer.",
  techStack: "TypeScript, Vue, Angular, CI/CD pipelines.",
  link: "https://www.factset.com/",
  linkType: "live",
  image: FactSet,
  imageAlt: "Stock Exchange Board",
};

const theNois = {
  title: "TheNOIS",
  about:
    "TheNOIS is a service which provides songwriters with the ability to collect their unpaid royalties accrued from various streaming services.",
  position: "Full Stack Developer.",
  techStack:
    "TypeScript, React, Redux, Neo4j, Cypher, Google Analytics, Stripe.",
  link: "https://www.thenois.org/",
  linkType: "live",
  image: TheNOIS,
  imageAlt: "TheNOIS site snapshot",
};

const acdc = {
  title: "Adam's Closet Design Culture",
  about:
    "Adam's Closet Design Culture is a locally owned Chicago-area business that offers custom closet installation, closet design, and painting services.",
  position: "Frontend Developer.",
  techStack: "JavaScript, React, HTML, CSS.",
  link: "https://acdcclosets.com/",
  linkType: "live",
  image: ACDC,
  imageAlt: "Adam's Closet Design Culture site snapshot",
};

const workContent = [factSet, acdc, theNois]; // order of content

export default workContent;
